<template>
	<div class="w-100 overflow-hidden">
		<div class="marquee">
			<ul class="list-inline">
				<li v-for="user in usersOnline" :key="user.slug">
					<img
						:src="user.country_flag"
						:alt="`Bandera del usuario ${user.nick}`"
					/>
					<router-link
						:to="{
							name: 'PlayerHome',
							params: { lang: lang, console: console, playerSlug: user.slug },
						}"
					>
						{{ user.nick }}
					</router-link>
				</li>
			</ul>
		</div>
		<div class="wrapper-title-tournament" v-if="showName">
			<h6 class="title w-100 text-left">{{ $t('online') }}</h6>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		showName: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters(['lang', 'console', 'usersOnline']),
	},
	mounted() {
		if (this.usersOnline.length < 1) {
			this.fetchData()
		}
	},
	methods: {
		fetchData() {
			this.$store.dispatch('SET_LOADING', true)
			const console = this.$route.params.console
			const path = `${this.lang}/console/${console}/users-online`
			this.$axios.get(path).then((response) => {
				this.$store.dispatch('SET_USERS_ONLINE', response.data.data)
				this.$store.dispatch('SET_LOADING', false)
			})
		},
	},
}
</script>
